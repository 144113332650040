'use strict';

(function () {
  const hasWebComponents = ('customElements' in window);
  if (!hasWebComponents) {
    const $script = document.createElement('script');
    $script.src = '/js/old-browser.display.js';
    document.head.appendChild($script);
  }
})();
